import { z } from "zod";
import { createEnv } from "@t3-oss/env-nextjs";
import { env as runtimeEnv } from 'next-runtime-env';

export const env = createEnv({
  skipValidation: !!process.env.SKIP_ENV_VALIDATION || !!process.env.BUILDING,
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    NODE_ENV: z.enum(["development", "test", "production"]),
    FRONTEND_REVALIDATION_TOKEN: z.string().min(7),
    REVALIDATE_PAGE_TIME_MINUTES: z.preprocess(Number, z.number()).default(5),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_MAP_API: z.string().default(""),
    NEXT_PUBLIC_CMS_URL: z.string().url().default("http://localhost:4000"),
    NEXT_PUBLIC_URL: z.string().url().default("http://localhost:3000"),
    NEXT_PUBLIC_GOOGLE_ANALYTICS_ID: z.string().default(""),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    FRONTEND_REVALIDATION_TOKEN: process.env.FRONTEND_REVALIDATION_TOKEN,
    REVALIDATE_PAGE_TIME_MINUTES: process.env.REVALIDATE_PAGE_TIME_MINUTES,
    NEXT_PUBLIC_MAP_API: runtimeEnv("NEXT_PUBLIC_MAP_API"),
    NEXT_PUBLIC_CMS_URL: runtimeEnv("NEXT_PUBLIC_CMS_URL"),
    NEXT_PUBLIC_URL: runtimeEnv("NEXT_PUBLIC_URL"),
    NEXT_PUBLIC_GOOGLE_ANALYTICS_ID: runtimeEnv("NEXT_PUBLIC_GOOGLE_ANALYTICS_ID"),
  },
});
