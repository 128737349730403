// Polyfills
import "core-js/actual/structured-clone";
import "core-js/features/object/from-entries";

import "~/styles/globals.css";

import { useEffect, useState } from "react";
import type { AppProps } from "next/app";
import { GoogleAnalytics } from "@next/third-parties/google";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { env } from "~/env";

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  // State to track if the user has interacted with the page
  const [hasInteracted, setHasInteracted] = useState(false);

  // Detect first user interaction to load Google Analytics
  useEffect(() => {
    const handleUserInteraction = () => {
      setHasInteracted(true);
      removeEventListeners();
    };

    const addEventListeners = () => {
      window.addEventListener("mousemove", handleUserInteraction);
      window.addEventListener("scroll", handleUserInteraction);
      window.addEventListener("touchstart", handleUserInteraction);
      window.addEventListener("keydown", handleUserInteraction);
      window.addEventListener("click", handleUserInteraction);
    };

    const removeEventListeners = () => {
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("scroll", handleUserInteraction);
      window.removeEventListener("touchstart", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
      window.removeEventListener("click", handleUserInteraction);
    };

    addEventListeners();

    return removeEventListeners;
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Component {...pageProps} />
      {hasInteracted && (
        <GoogleAnalytics gaId={env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID} />
      )}
    </QueryClientProvider>
  );
}

// Wrap Next app in React Query context
export default MyApp;
